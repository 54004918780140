<template>
  <div class="choosingFurniture">
    <header-nav></header-nav>
    <div class="choosingFurniture-box">
      <b-container class="choosingFurniture-cont" fluid>
        <b-row class="style">
          <b-col class="style-title" sm="4" md="3" lg="3" xl="2">
            <img
              src="../../../../public/icon/mall/choosingFurniture/w1.jpg"
              alt=""
            />
            <span>风格</span>
          </b-col>
          <b-col
            class="style-item"
            v-for="(item, index) in styleList"
            :key="index"
            sm="4"
            md="2"
            lg="2"
            xl="1"
            @click="chooseStyle(item)"
          >
            <img v-if="item.imgUrl" :src="item.imgUrl" alt="" />
            <span :class="style_type == item.id?'chooseStyleColor':''">{{ item.name }}</span>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="category-box">
      <b-container class="category-cont" fluid>
        <b-row class="category">
          <b-col class="category-title" sm="4" md="3" lg="3" xl="2">
            <img
              src="../../../../public/icon/mall/choosingFurniture/w2.jpg"
              alt=""
            />
            <span>类别</span>
          </b-col>
          <b-col :class="second_label_id == ''?'category-all-chd':'category-all'" sm="4" md="2" lg="2" xl="1" @click="chooseSpace('','')">
            全部
          </b-col>
          <b-col
                class="category-number"
                v-for="(item, index) in spaceImgList"
                :key="index"
                @click="chooseSpace(index,item)"
                sm="2" md="3" lg="3" xl="2"
              >
                <img
                  :src="require(`@/../public/icon/mall/choosingFurniture/${item.imgUrl}`)"
                  alt=""
                />
                <span>{{item.name}}</span>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="screening-box">
      <b-container class="screening-cont" fluid>
        <b-row class="screening">
          <b-col
          @click="chooseSort(item)"
            :class="sort_type==item.id?'screening-active':'screening-item'"
            v-for="(item, index) in screeningList"
            :key="index"
            sm="2"
            md="2"
            lg="2"
            xl="1"
          >
            {{ item.name }}
          </b-col>
          <b-col class="screening-price" sm="2" md="2" lg="2" xl="1" @click="chooseSort({id:3})">
            <span :class="sort_type==3||sort_type==4?'price-txt-chd':'price-txt'">价格</span>
            <div class="price-symbol">
              <span :class="sort_type==3&&priceTrue?'price-up-chd':'price-up'"></span>
              <span :class="sort_type==3&&priceTrue?'price-down-chd':'price-down'"></span>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="goods-box">
        <b-container class="goods-cont" fluid>
            <b-row class="goods">
                <b-col class="goods-item" v-for="(item,index) in goodsList" :key="index" sm="12"
            md="6"
            lg="6"
            xl="3">
                    <div class="goods-in" @click="toProductDetail(item)">
                        <img :src="item.file_path" alt="">
                        <span class="goods-name">{{item.goods_name}}</span>
                        <span class="goods-price">￥{{item.sale_price}}</span>
                        <div class="goods-unit">
                            <span class="sale-txt">已售</span>
                            <span class="sale-num">{{item.sale_count}}</span>
                            <span class="sale-txt">件</span>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <el-pagination
        v-if="totalPage > 0"
        :page-count="totalPage"
        @current-change="changeCurrentPage"
        class="margin-top-80"
        background
        layout="prev, pager, next"
      ></el-pagination>
        </b-container>
    </div>
    <right></right>
    <footer-nav></footer-nav>
  </div>
</template>
<script>
  import headerNav from "@/components/Navbar";
  import right from "@/components/Right";
  import footerNav from "@/components/Footer";
  import dump from "@/util/dump";
export default {
  components: {
    headerNav,
    right,
    footerNav
  },
  data() {
    return {
      goodsList:[],//产品数据
      styleList: [
        //风格数据
        { name: "全部", imgUrl: "",id:'' },
        {
          name: "极简",
          imgUrl:
            "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/da8b21a3-916f-447a-8b60-2e95bb517619.png",
          id:'16'
        },
        {
          name: "轻奢",
          imgUrl:
            "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/1bb4a33d-6773-4042-9ec9-bb8b3133cb7a.png",
          id:'15'
        },
        {
          name: "新中式",
          imgUrl:
            "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/233d7b53-5eb7-41a8-bee7-dc1a5706f4bb.png",
          id:'53'
        },
      ],
      spaceImgList:[//空间分类数据
        {
          name:'客厅',
          imgUrl:'living.jpg',
          id:'5'
        },
        {
          name:'餐厅',
          imgUrl:'restaurant.jpg',
          id:'107'
        },
        {
          name:'卧室',
          imgUrl:'bedroom.jpg',
          id:'106'
        },
        {
          name:'书房',
          imgUrl:'study.jpg',
          id:'111'
        }
      ],
      spaceIndexDef:-1,//空间默认下标
      screeningList: [{name:'综合',id:''}, {name:"人气",id:'5'}, {name:"销量",id:'1'}, {name:"新品",id:'2'}, {name:"模型", id:'6'}],//筛选数据
      style_type:'',//选择风格id
      second_label_id:'',//二级分类id
      sort_type:'',//筛选id
      third_label_id:'',//三级分类id
      currentPage:1,//当前页码
      priceTrue:false,//当前是否为价格筛选
      totalPage:0//总页数
    };
  },
  mounted(){
    this.getData()
  },
  methods:{
    //获取产品列表
    getData(){
      const that = this
      let para = {
        style_type: that.style_type,
				second_label_id: that.second_label_id,
				goods_type: 1,
				sort_type: that.sort_type,
				third_label_id: that.third_label_id,
				currentPage: that.currentPage
      }
      that.$api.choosingFurnitureData(para).then((res)=>{
        if(res.status == 100) {
          that.goodsList = res.data.goods
          that.totalPage = res.data.totalPage
        }
      })
    },
    //选择风格
    chooseStyle(item){
      const that = this
      that.style_type = item.id
      that.currentPage = 1
      that.getData()
    },
    //选择空间
    chooseSpace(index,item){
      const that = this
      if(item.id){
        if(that.spaceIndexDef != index){
          if(that.spaceIndexDef != -1){
            let imgDef = that.spaceImgList[that.spaceIndexDef].imgUrl.replace('-checked.','.')
            that.$set(that.spaceImgList[that.spaceIndexDef],'imgUrl',imgDef)
          }
          item.imgUrl = item.imgUrl.replace('.','-checked.')
          that.$set(that.spaceImgList[index],'imgUrl',item.imgUrl)
          that.second_label_id = item.id
          that.spaceIndexDef = index
          that.currentPage = 1
          that.getData()
        }
      }else{
        if(that.spaceIndexDef != -1){
          let imgDef = that.spaceImgList[that.spaceIndexDef].imgUrl.replace('-checked.','.')
          that.$set(that.spaceImgList[that.spaceIndexDef],'imgUrl',imgDef)
          that.second_label_id = ''
          that.spaceIndexDef = -1
          that.currentPage = 1
          that.getData()
        } 
      }
    },
    //筛选
    chooseSort(item){
      const that = this
      if(item.id == 3){
        that.priceTrue = true
        if(that.sort_type == item.id){
          that.sort_type = 4
        }else{
          that.sort_type = 3
        }
      }else{
        that.priceTrue = false
        that.sort_type = item.id
      }
      that.currentPage = 1
      that.getData()
    },
    //切换页码
    changeCurrentPage(info) {
      this.currentPage = info;
      this.getData();
      this.$utils.scrollTop();
    },
    toProductDetail(obj){
      dump.link({
        type: 3,
        link: "/product/details",
        params: {
          good_id: obj.goods_id
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/view/mall/choosingFurniture/choosingFurniture";
</style>